h4 {
    text-align: start;
    font-weight: bolder;
    font-size: 21px;
    color: rgb(87, 87, 87);
    margin-top: 15px;
    margin-bottom: 15px;
    flex-grow: 1;
    line-height: 35px;
}

.horizontal-scrollable {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
}