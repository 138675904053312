.accordion-button {
    background-color: rgb(242, 242, 242);
    color: rgb(82, 82, 82);
    font-size: medium;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.accordion-button:hover {
    background-color: rgb(222, 222, 222);
}

.accordion-button:focus {
    background-color: rgb(222, 222, 222);
}

.accordion-button:not(.collapsed) {
    background-color: rgb(20, 94, 255);
    color: white;
}

.accordion-body {
    background-color: rgb(230, 238, 255);
}