.head {
    background-color: rgb(0, 151, 111);
    color: aliceblue;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.main-title {
    color: white;
    font-weight: bold;
    font-size: medium;
}

.secondary-title {
    font-size: small;
    margin-top: 0px;
    color: rgba(255, 255, 255, 0.767);
}

.navbar-toggler-icon {
    width: 35px;
    background-image: url('../../images/vuesax-outline-menu.svg') !important;
}