.rounded-bg-main {
     width: auto;
     height: auto;
     padding: 25px;
     margin: 25px 0 25px 0;
     background-color: white;
     border-radius: 15px;
     word-break: normal;
     box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
     overflow: hidden;
}

.card-rounded-shadowed {
     width: auto;
     height: auto;
     padding: 8px;
     display: block;
     background-color: white;
     border-radius: 10px;
     word-break: normal;
     box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.danger-alert {
     background-color: #FF406E;
     border-radius: 15px;
     color: white;
     font-size: medium;
     text-align: start;
     font-weight: bold;
     padding-top: 20px;
     padding-bottom: 20px;
     padding-left: 35px;
     padding-right: 35px;
}

.horizontal-scrollable {
     width: auto;
     overflow-x: auto;
     display: flex;
     padding: 0;
     flex-direction: row;
     align-items: flex-start;
     justify-content: left;
}

.img-avatar {
     width: 45px;
     height: 45px;
     border-radius: 100%;
}

.frame-layout {
     position: relative;
     display: inline-flex;
}

#back_button {
     border-radius: 100px;
     padding-top: 8px;
     width: fit-content;
     padding-bottom: 8px;
     padding-left: 8px;
     padding-right: 18px;
     cursor: pointer;
     margin-right: auto;
     color: #00b3ff;
     background-color: #00b3ff18;
}

#back_button img {
     width: 24px;
     height: 24px;
}

#reptile-thumbnail {
     border: none;
     border-style: none;
     border-radius: 25px;
     -webkit-border-radius: 25px;
     -moz-border-radius: 25px;
     z-index: 100;
}