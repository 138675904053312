body {
     background-color: #fff;
}

h6 {
     text-align: start;
     font-weight: bold;
}

.text-secondary {
     margin-top: 15px;
     margin-bottom: 15px;
}

.horizontal-scrollable {
     width: 100%;
     overflow-x: auto;
     display: flex;
     flex-direction: row;
}

.horizontal-scrollable .card img {
     width: 18rem;
     height: 10rem;
}

.card-near-you {
     width: 12rem;
     height: auto;
}

.horizontal-paddings-15 {}

.grid-paddings-15 {
     /* padding-left: 15px;
     padding-right: 15px; */
     display: grid;
     margin: 0;
     grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
     grid-gap: 1.5rem;
     max-width: 100%;
     width: 15rem;
}

.card {
     margin-bottom: 12px;
     border-radius: 15px;
     border: none;
     box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
}

.card-img-top {
     width: auto;
     height: 12rem;
     border-radius: 15px;
     margin: 15px;
}

.card-body {
     padding-top: 0;
     margin-top: 0;
     padding-bottom: 10px;
     display: flex;
     flex-direction: column;
     line-height: 0;
     text-decoration: none;
     align-items: center;
}

.card-text {
     color: gray;
}

.tab-menu-item {
     background-color: gray !important;
     background: gray !important;
     padding: 12px;
     color: white;
     font-size: medium;
     margin-right: 10px;
     cursor: pointer;
}

.tab-menu-item-active {
     padding: 12px;
     color: white;
     font-size: medium;
     margin-right: 10px;
     cursor: pointer;
}

#faq-container {
     background-color: #fff;
     border-radius: 12px;
     padding-left: 12px;
     padding-right: 12px;
     padding-top: 8px;
     width: 100%;
     margin-top: 16px;
     display: flex;
     cursor: pointer;
     flex-direction: row;
     padding-bottom: 8px;
     filter: drop-shadow(0 0 0.55rem rgb(194, 194, 194));
     text-align: start;
}